.header {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  position: relative;
  transition: 0.4s;

  .userTitle {
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: white;
    box-sizing: border-box;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .userTitleExpanded {
    transition: 0.4s;
  }

  .expandableHeader {
    background-image: linear-gradient(to top, #07154a, #132468);
    border-radius: 0px 0px 20px 20px;
    padding: 1rem;
    padding-bottom: 2rem;

    .logoContainer {
      .imgHeader {
        margin: auto;
        transition: 0.4s;
        height: 20px;
        width: 20px;
      }
    }
  }
}
