.memberInfoContainer {
  
  width: 20px;
  height: 100%;
  text-align: center;

  .memberInfoCount {
    
    width: 100%;
    height: 16px;
    font-size: 16px;
    margin-bottom: 6px;
  }

  .memberInfoIcon {
    
    width: 100%;
    height: 20px;

    svg {
      width: 20px;
      height: 17px;

      path {
        fill: black;
      }
    }
  }
}

.memberInfoContainerAlert {
  .memberInfoCount {
    color: #e81579 !important;
  }

  .memberInfoIcon {
    path {
      fill: #e81579 !important;
    }
  }
}
