.listElementContainer {
  box-sizing: border-box;
  display: block;
  width: 100%;

  .listElement {
    padding: 1rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .alertInfoContainer {
      display: flex;
      align-items: center;

      .iconContainer {
        margin-right: 15px;
        display: block;

        path {
          fill: #e81579;
        }
      }

      .alertInfo {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .alertTitle {
          width: 100%;
          font-size: 14px;
          letter-spacing: 1.54px;
          text-align: left;
          color: #000000;
          margin-bottom: 5px;
        }

        .alertSubtitle {
          width: 100%;
          font-size: 12px;
          letter-spacing: 1.32px;
          text-align: left;
          color: #000000;
        }
      }
    }

    .dateContainer {
      height: 100%;
      width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .date {
        width: 100%;
        font-size: 10px;
        letter-spacing: 1.1px;
        text-align: center;
        color: #000000;
      }
    }
  }

  .changeAlertStatusButtonsContainer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .buttonTitle {
      width: 100%;
      height: 10px;
      font-size: 8px;
      text-align: center;
      color: #ffffff;
    }

    .buttonIconContainer {
      height: 28px;
      text-align: center;
      margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;

      svg {
        width: 24px;
        height: 28px;
        path {
          fill: white;
        }
      }
    }

    .deleteButton {
      width: 74px;
      height: 100%;
      background-color: #dddddd;
      box-sizing: border-box;
      border: none;
      outline: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .readUnreadButton {
      width: 74px;
      height: 100%;
      background-color: #2f307e;
      box-sizing: border-box;
      border: none;
      outline: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.openedAlertButtons {
  transition: 0.4s;
  right: 148px !important;
}

.listElement:hover {
  background-color: #f1f1f8;
}
