.navbar {
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  position: fixed;
  box-sizing: border-box;
  padding: 5px 0px 10px 0px;
  bottom: 0;
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: space-evenly;
  z-index: 1000;
  margin-bottom: env(safe-area-inset-bottom);
}
