.footerLinkContainer {
  align-self: flex-end;
  width: 45px;
  height: 32.3px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  a:-webkit-any-link {
    text-decoration: none;
  }

  .footerLinkName {
    width: 100%;
    height: 8px;
    font-size: 8px;
    color: #bababa;
  }

  .footerLinkIcon {
    width: 100%;
    height: 20px;
    margin-bottom: 3px;
  }

  svg {
    margin: auto;
    height: 20px;
    width: 20px;
  }

  path {
    fill: #bababa;
  }
}

.active {
  path {
    fill: #2f307e;
  }

  .footerLinkName {
    color: #2f307e;
  }
}
