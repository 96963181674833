.goBackContainer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  .goBackIconContainer {
    width: 7px;
    height: 13px;
    margin-right: 18px;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      path {
        fill: white;
      }
    }
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    color: white;
  }
}
