/* The Modal (background) */
.modalBackground {
  
  display: none; /* Hidden by default */
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 15; /* Sit on top */
  width: 100%; /* Full width */
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
}

/* Modal Content */
.modalContent {
  height: 163px;
  display: none;
  top: 187px;
  margin-left: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  position: absolute;
  z-index: 20;
  padding: 25px 20px;
  border-radius: 5px;
  background-color: #ffffff;

  .modalTitle {
    
    width: 100%;
    height: 32px;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 48px;
  }

  .modalButtonsContainer {
    
    width: 100%;
    height: 36px;

    .buttonDivider {
      
      width: 10%;
      height: 100%;
    }

    .modalButton {
      
      width: 45%;
      height: 36px;
      border: none;
      border-radius: 5px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      background-color: #ed1c79;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
    }
  }
}

.modalDisplay {
  display: block;
}
