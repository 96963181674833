.tab {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  letter-spacing: .5px;
  text-align: center;
  color: #666666;
  cursor: pointer;
  border-bottom: 2px solid #e8e8e8;

  &.tabSelected {
    border-bottom: 2px solid #c24685;
  }

  .labelSelected {
    color: black;
  }

  &:hover {
    .labelSelected {
      color: black;
    }
  }
  &:hover {
    color: black;
  }
}

.buttonTab {
  background-color: #f4f4f4;
  text-align: center;
  flex: 1;

  .selected {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
}

.tabsContainer {
  width: 100%;
  padding: 12px 0;
  display: flex;
}
