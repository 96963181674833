.listElement {
  display: flex;
  width: 100%;
  height: 48px;

  .sensorStatusContainer {
    display: flex;
    width: calc(100% - 9px);
    height: 100%;

    .sensorStatus {
      width: 8px;
      height: 100%;
      margin-right: 7px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        border-color: white;
      }
    }

    .iconContainer {
      width: 30px;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      svg {
        width: 30px;
        height: 30px;
        path {
          fill: #8b8b8b;
        }
      }
    }

    .sensorId {
      width: calc(100% - 45px);
      box-sizing: border-box;
      padding: 0px 9px;
      height: 100%;
      line-height: 48px;
      font-size: 14px;
      letter-spacing: 1.54px;
      text-align: left;
      color: #000000;
    }
  }

  .goForwardIconContainer {
    width: 9px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    svg {
      width: 5px;
      height: 9px;
      path {
        fill: #2f307e;
      }
    }
  }
}

.listDivider {
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  background-clip: content-box;
  background-color: #f8f8f8;
  padding: 0px 34px 0px 34px;
}

.listElement:hover {
  background-color: #f1f1f8;
}
