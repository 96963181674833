.enabledIcon {
  fill: black;
}

.notEnabledIcon {
  fill: lightgray;
}

.buttonContainer {
  height: auto;
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  .buttonSize {
    width: 200px;
  }
}

.body {
  padding: 5px 9px 5px 9px;
  box-sizing: border-box;
  width: 100%;
}
