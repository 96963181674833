.responsiveTable {
  overflow-x: auto;
  height: auto;
  width: 100%;
  border-radius:8px;
  border: 1px solid #E2E8F0;

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    border-radius:8px;
  }

  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
    
  }

  table tr {
    font-size: 14px;
    border-bottom: 1px solid #E2E8F0;
    padding: 0.35em;
    border-radius:8px;
  }
  tr:last-child{
    border-bottom: none;
  }
  tr:first-child{
    border-bottom: 1px solid #E2E8F0
  }

  table th,
  table td {
    padding: 0.625em;
    text-align: center;
    font-size: 14px;
  }

  table th {
    font-size: 14px;
    letter-spacing: 0.1em;
  }

  @media screen and (max-width: 600px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 14px;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #E2E8F0;
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #E2E8F0;
      border-radius: 8px;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);

      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }

  .paginationContainer {
    height: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .rowsPerPageContainer {
      width: 200px;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 10px;

      .selectTitle {
        width: 100%;
        height: 100%;
        font-size: 12px;
      }

      .selectContainer {
        width: 100%;
        height: 100%;
      }
    }

    .pagesContainer {
      height: 100%;
      display: flex;
      align-items: center;

      .title {
        width: 70px;
        height: 100%;
        font-size: 12px;
      }

      .iconContainer {
        height: 100%;
        width: auto;
        margin-right: 10px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        svg {
          height: 20px;
          width: 20px;

          path {
            fill: black;
          }
        }
      }

      .disabledIcon {
        cursor: default;
        svg {
          path {
            fill: lightgray;
          }
        }
      }
    }
  }
}
