.alertCard {
  width: 100%;
  height: 236px;
  box-sizing: border-box;
  padding: 12px 14px;
  border-radius: 10px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .rowItem {
    display: flex;
  }

  .rowItem:not(:first-of-type) {
    margin-top: 1rem;
  }
}
