.header {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  position: relative;
  padding-top: env(safe-area-inset-top);
  background: linear-gradient(
    90deg,
    rgba(193, 70, 132, 1) 0%,
    rgba(204, 99, 152, 1) 50%
  );

  .logoContainer {
    border: none;
    outline: none;
    width: 100%;
  }

  .imgHeader {
    margin: auto;
    margin-top: 10px;
    height: 20px;
    width: 20px;
  }

  .areaName {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: white;
  }
}
