@import "../../styles/all";

.loginPage {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.headerLogin {
  box-sizing: border-box;
  height: 237px;
  width: 100%;
  background: linear-gradient(
    45deg,
    rgb(18, 36, 108) 00%,
    rgb(237, 28, 121) 100%
  );
  text-align: center;
  border-radius: 0px 0px 35px 35px;
  padding-top: 86px;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin: auto;
    max-width: 300px;
    height: 62px;
  }
}

.body {
  width: 100%;
  box-sizing: border-box;
  padding: 65px 21px 0px 21px;

  .formInput {
    text-align: center;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .errorBox {
    .errorMessage {
      padding-top: 10px;
      margin: 0px;
      font-size: 1rem;
      color: green;
    }
  }
}

.btnForm {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 0px 41px 41px 41px;
  background-color: white;

  .forgotPassword {
    box-sizing: border-box;
    padding-top: 10px;
    height: 40px;
    text-align: center;
    a {
      text-decoration: none;
      font-size: 1rem;
      color: rgb(18, 36, 108);
    }
  }
}
