/* The Modal (background) */
.modalBackground {
  display: none; /* Hidden by default */
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 15; /* Sit on top */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.2);
}

/* Modal Content */
.modalContent {
  top: 70px;
  width: 80%;
  position: absolute;
  z-index: 20;
  padding: 25px 20px;
  border-radius: 5px;
  background-color: #ffffff;
  display: none;

  .modalTitle {
    width: 100%;
    height: 32px;
    font-size: 20px;
    text-align: left;
    font-weight: 600;
    color: #000000;
    margin-bottom: 36px;
  }

  .formBodyContainer {
    width: 100%;
    box-sizing: border-box;
    text-align: center;

    .formInputs {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem 2rem;

      .formInput {
        width: 100%;
        text-align: center;

        .inputValidationMessage {
          width: 100%;
          height: 40px;
          text-align: left;
          padding-top: 1px;
          font-size: 12px;
          color: rgb(237, 28, 121);
        }
      }
    }
  }
}

.modalDisplay {
  display: block;
}
