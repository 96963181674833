.alertCardRow {
  height: 20px;
  width: 100%;
  box-sizing: border-box;

  .iconContainer {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    margin-right: 10px;

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #969696;
      }
    }
  }

  .title {
    box-sizing: border-box;
    font-size: 12px;
    letter-spacing: 1.32px;
    text-align: left;
    color: #000000;
  }
}
