@import "../../styles/colors.scss";

.button {
  height: 3rem;
  line-height: 3rem;
  padding: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  border: none;
  color: white;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button:disabled {
  background: #ccc;
  cursor: default;
  box-shadow: none;
}
