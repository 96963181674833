.footerLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  path {
    fill: black;
  }
  .footerLinkName {
    color: black;
  }
}
.active {
  path {
    fill: #c24685;
  }

  .footerLinkName {
    color: #c24685;
  }
}
