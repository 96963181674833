.headerMembersInfoContainer {
  margin-top: -1.5rem;
  height: 140px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(217, 217, 217, 0.25);
  background-color: #fdfdfd;
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 15px 20px 15px 20px;

  .arrowIcon {
    width: 12px;
    display: flex;
    align-items: center;
    svg {
      width: 12px;
      height: 22px;
      path {
        fill: #969696;
      }
    }
  }
}
