.header {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  position: relative;
  background-image: linear-gradient(to top, #07154a, #132468);
  padding-bottom: 0.25rem;
  padding-top: 10px;

  .expandableHeader {
    width: 100%;
    box-sizing: border-box;

    .searchBar {
      box-sizing: border-box;
      width: 100%;
      height: 25px;
      padding: 0px 8px 0px 8px;
    }
  }

  .headerRetracted {
    transition: 0.4s;
    .searchBar {
      visibility: visible;
    }
  }

  .imgHeader {
    margin: auto;
    height: 20px;
    width: 20px;
  }
}
