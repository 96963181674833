.buttonContainer {
  height: auto;
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  .buttonSize {
    text-align: right;
    width: 200px;
  }
}
