/* The Modal (background) */
.modalBackground {
  
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 15; /* Sit on top */
  width: 100%; /* Full width */
  height: calc(100% - 50px - env(safe-area-inset-bottom)); /* Full height */
  background-color: rgba(0, 0, 0, 0.1); /* Black w/ opacity */
}

/* Modal Content */
.modalContent {
  
  width: calc(100% - 40px);
  display: none;
  top: 160px;
  margin-left: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  position: absolute;
  height: auto;
  z-index: 20;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #ffffff;

  .filterRow {
    
    width: 100%;
    height: 17px;
    margin-bottom: 12px;
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;

    .pointContainer {
      
      width: 15px;
      box-sizing: border-box;
      padding: 6px 0;
      height: 100%;

      .selectedRow {
        
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000000;
      }

      .notSelectedRow {
        
        width: 100%;
        height: 100%;
      }
    }

    .filterText {
      
      box-sizing: border-box;
      padding-top: 2px;
      width: calc(100% - 15px);
      height: 17px;
      font-size: 12px;
      text-align: left;
      color: #000000;
    }
  }

  .filterRow:last-child {
    margin-bottom: 0px;
  }
}

.modalDisplay {
  display: block;
}
