.memberInfoContainer {
  width: 90px;
  height: 100%;
  text-align: center;

  .memberInfoIcon {
    width: 100%;
    height: 25px;

    margin-bottom: 7px;
    svg {
      height: 25px;

      margin: auto;
      width: auto;

      path {
        fill: black;
      }
    }
  }

  .memberInfoCount {
    width: 100%;
    height: 28px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: black;
    margin-bottom: 4px;
  }

  .memberInfoName {
    width: 100%;
    height: 16px;
    font-size: 16px;
    text-align: center;
    color: black;
    margin-bottom: 14px;
  }

  .alertsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 16px;
    border-radius: 10px;
    font-size: 10px;
    color: white;
    text-align: center;
    background-color: #ed1c79;
    box-sizing: border-box;
    b {
      font-size: 14px;
    }
  }
}
