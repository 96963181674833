@import "../../styles/all";

.input {
  border: none;
  box-sizing: border-box;
  text-align: left;
}

.inputElement:focus {
  border: 1px solid $border-input-color;
}

.label {
  text-align: left;
  font-size: 16px;
  color: rgb(167, 167, 167);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $text-form-color;
  opacity: 1; /* Firefox */
}

.inputElement {
  outline: none;
  color: $text-form-color;
  border: none;
  height: 40px;
  background-color: $input-background-color;
  border-radius: 3px;
  font: inherit;
  font-size: 16px;
  padding: 6px 10px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: $placeholder-form-color;
  }
  &::-moz-placeholder {
    color: $placeholder-form-color;
  }
  &:-ms-input-placeholder {
    color: $placeholder-form-color;
  }
  &:-moz-placeholder {
    color: $placeholder-form-color;
  }
}

.checkboxElement {
  outline: none;
  color: $text-form-color;
  border: none;
  height: 40px;
  border-radius: 3px;
  font: inherit;
  font-size: 1rem;
  padding: 6px 10px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.invalid {
  border: 1px solid #ed1c79 !important;
}
