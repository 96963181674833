.button {
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
  padding: 0px 8px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #2f307e;

  .titleButtonContainer {
    height: 100%;
    display: flex;

    .title {
      width: auto;
      font-size: 14px;
      box-sizing: border-box;
      text-align: left;
      color: #ffffff;
      margin-right: 10px;
    }

    .titleIconContainer {
      width: 18px;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
        path {
          fill: white;
        }
      }
    }
  }

  .goToIconContainer {
    width: 8px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    svg {
      width: 8px;
      height: 14px;
      path {
        fill: white;
      }
    }
  }
}
