.gauchoApp_fieldSelector {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-self: center;
  justify-self: center;
  margin: 10px 0px;
  .gauchoApp_fieldSelect_form {
    border: 2px #666cff solid;
    border-radius: 6px;
    width: 335px;
    height: 56px;
    .gauchoApp_fieldSelect_label {
      background-color: #f2f2f7;
      margin-top: -8px;
      margin-left: 12px;
      padding-left: 2px;
      padding-right: 2px;
      color: #666cff;
    }
    .gauchoApp_fieldSelect_label_disabled {
      color: gray;
    }

    div {
      text-align: start;
      padding-left: 5px;
      padding-right: 5px;
      color: #4c4e64de;
      background-color: #f2f2f7;
    }

    input {
      background-color: #f2f2f7;
      text-align: start;
      border: none;
      text-decoration: none;
    }
  }
  .gauchoApp_fieldSelect_form_disabled {
    border: 2px gray solid;
  }
}
