.formContainer {
  width: 50%;

  .formInput {
    .inputValidationMessage {
      width: 100%;
      height: 40px;
      text-align: left;
      padding-top: 1px;
      font-size: 12px;
      color: rgb(237, 28, 121);
    }
  }
}
