.goBackHeader {
  height: 70px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0 27px 0;

  .goBackIconContainer {
    height: 23px;
    width: calc(100% - 38px);

    .buttonGoBack {
      width: 13px;
      height: 23px;
      border: none;
      outline: none;
      background-color: transparent;

      svg {
        transform: rotate(180deg);
        width: 13px;
        height: 23px;
        path {
          fill: black;
        }
      }
    }
  }

  .deleteContainer {
    width: 38px;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;

    .deleteIconContainer {
      width: 100%;
      height: 12px;
      box-sizing: border-box;
      padding: 0 13px;

      svg {
        width: 11px;
        height: 12px;
        path {
          fill: black;
        }
      }
    }

    .deleteTitle {
      width: 100%;
      height: calc(100% - 12px);
      box-sizing: border-box;
      padding-top: 3px;
      font-size: 8px;
      text-align: center;
      color: #000000;
    }
  }
}

.buttonsListContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 24px 0 0 0;
}
