.loaderSpin {
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
}

.buttonLoader{
  display: block;
  margin: auto;
  width: 30px;
  height: 30px;
}