.header {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: calc(110px + env(safe-area-inset-top));
  text-align: center;
  position: relative;
  padding-top: env(safe-area-inset-top);
  background: linear-gradient(to top, #2f307e, #586c9d);

  .logoContainer {
    border: none;
    outline: none;
    height: 35px;
    width: 100%;
  }

  .imgHeader {
    margin: auto;
    margin-top: 10px;
    height: 20px;
    width: 20px;
  }

  .areaName {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: white;
  }
}

.body {
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: calc(100% - 130px - env(safe-area-inset-top));
  position: absolute;
  bottom: 50px;
  z-index: 5;

  .sortContainer {
    box-sizing: border-box;
    padding: 0 30px;

    height: 17px;
    width: 100%;
    font-size: 12px;
    text-align: left;
    color: #000000;
    margin-bottom: 13px;
    display: flex;
    align-items: center;

    .sortTitle {
      height: 100%;
      width: auto;
      margin-right: 10px;
    }

    .arrowIconContainer {
      svg {
        width: 13px;
        height: 7px;
        path {
          fill: #000000;
        }
      }
    }
  }
}
