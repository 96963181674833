.gauchoApp_container {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: start;
  min-height: 90vh;

  p {
    margin-top: 30px;
    color: #4c4e64de;
    font-weight: 500;
    font-size: 20px;
  }
  .guachoApp_fieldSelection_section {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    justify-content: center;
  }
  .gauchoApp_button {
    background-color: #c24685;
    --tw-gradient-stops: #c24685;
    height: 48px;
    width: 335px;
    font-weight: 500;
    margin-top: 8px;
    &:hover {
      opacity: 0.7;
    }
  }
}
