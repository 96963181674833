@import "../../styles/all";

.inputContainer {
  border: none;
  box-sizing: border-box;
  text-align: left;
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  position: relative;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;

  .iconContainer {
    width: 25px;
    height: 25px;
    z-index: 1;
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    path {
      fill: #8b8b8b;
    }
  }

  .inputSearch {
    width: 100%;
    outline: none;
    position: absolute;
    color: $text-form-color;
    border: none;
    height: 25px;
    font: inherit;
    font-size: 1rem;
    padding-left: 27px;
    border-radius: 12.5px;
    box-sizing: border-box;
    z-index: 2;
    background-color: transparent;
  }
}

.expandedInputContainer {
  width: 100%;
}

.fieldsSearchColor {
  background-color: #f0f0f0;

  .iconContainer {
    path {
      fill: #8b8b8b;
    }
  }
  .inputSearch {
    color: #8b8b8b;
  }
}

.mapSearchColor {
  background-color: rgba(255, 255, 255, 0.5);

  .iconContainer {
    path {
      fill: white;
    }
  }
  .inputSearch {
    color: white;
  }
}

.sensorsSearchColor {
  background-color: white;

  .iconContainer {
    path {
      fill: #2f307e;
    }
  }
  .inputSearch {
    color: #2f307e;
  }
}
