.modalContent {
  height: 24px;
  line-height: 24px;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  position: absolute;
  z-index: 50;
  border-radius: 5px;
  background-color: rgba(237, 28, 121, 0.8);

  .modalTitle {
    
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
  }
}

.modalDisplay {
  display: block;
}
