.sortContainer {
  box-sizing: border-box;
  padding: 0 27px;
  height: 17px;
  width: 100%;
  font-size: 12px;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;

  .sortTitle {
    height: 100%;
    width: auto;
    margin-right: 10px;
  }

  .arrowIconContainer {
    box-sizing: border-box;
    svg {
      width: 13px;
      height: 7px;
      path {
        fill: #000000;
      }
    }
  }
}

.alertsList {
  width: 100%;
  height: calc(100% - 67px);
  overflow: auto;
}

.alertPreview {
  background-color: white;
}
