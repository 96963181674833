.sensorsCountContainer {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 21px;
  font-size: 18px;
  text-align: left;
  color: #2f307e;
}

.searchBoxContainer {
  width: 100%;
  height: 25px;
  margin-top: 9px;
  margin-bottom: 9px;
  box-sizing: border-box;
  padding-left: 9px;
  padding-right: 9px;
}

.sensorsListContainer {
  width: 100%;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
  box-sizing: border-box;

  .filtersContainer {
    display: flex;
    width: 100%;
    height: 28px;
    border-bottom: 1px solid;
    border-color: #f1f1f1;

    svg {
      margin-left: 10px;
      width: 5px;
      height: 9px;
      transform: rotate(90deg);
      path {
        fill: #8b8b8b;
      }
    }

    .filter {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 7px 0;
      text-align: center;

      height: 100%;
      width: 50%;
      font-size: 14px;
      letter-spacing: 1.54px;
      color: #8b8b8b;
    }

    .filter:first-child {
      border-right: 1px solid;
      border-color: #f1f1f1;
    }

    .filter:last-child {
      border-left: 1px solid;
      border-color: #f1f1f1;
    }
  }

  .sensorsList {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 23px 0px 23px;
    overflow: auto;
  }
}
