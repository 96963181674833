.headerGauchoApp_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 70px;
  background-color: white;
  .headerGauchoApp_icon {
    width: 108px;
    height: 40px;
  }
}
