.footerGauchoApp_container {
  background-color: white;
  width: 100%;
  height: 56px;
  position: fixed;
  bottom: 0;
  display: flex;
  padding-inline: 50px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  z-index: 1001;
  .fotterGauchoApp_icon {
    color: red;
  }
}
