.filterContainer {
  box-sizing: border-box;
  padding: 0 27px;

  height: 17px;
  width: 100%;
  font-size: 12px;
  text-align: left;
  color: #000000;

  .sortTitle {
    height: 100%;
    width: auto;
    margin-right: 10px;
  }

  .arrowIconContainer {
    box-sizing: border-box;
    height: 100%;
    width: 13px;
    padding: 3px 0;
    svg {
      width: 13px;
      height: 7px;
      path {
        fill: #000000;
      }
    }
  }
}

.alertsList {
  width: 100%;
  height: calc(100% - 17px);
  overflow: auto;
}

.alertTitleColor {
  color: #e81579;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.alertsList::-webkit-scrollbar {
  display: none;
}

.alertColor {
  background-color: #e81579;
}

.alertListElementBackground {
  background-color: #f8f8f8;
}
