.loginPage {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 80%;
  margin: auto;
  background-color: whitesmoke;

  .logInFormContainer {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;

    .formHeaderContainer {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;

      .logoContainer {
        width: 100px;
        height: 50px;
        svg {
          width: 100px;
          height: 50px;
          path {
            fill: rgb(18, 36, 108);
          }
        }
      }
    }

    .formBodyContainer {
      max-width: 500px;
      width: 100%;
      box-sizing: border-box;
      padding: 0px 21px 0px 21px;
      overflow: auto;
      text-align: center;
      margin: auto;

      .formInputs {
        .formInput {
          text-align: center;
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      button {
        cursor: pointer;
      }

      .errorBox {
        box-sizing: border-box;
        height: 40px;
        .errorMessage {
          padding-top: 10px;
          margin: 0px;
          font-size: 14px;
          color: rgb(237, 28, 121);
        }
      }
    }
  }
}

.headerLogin {
  box-sizing: border-box;
  height: 237px;
  width: 100%;
  background: linear-gradient(
    45deg,
    rgb(18, 36, 108) 00%,
    rgb(237, 28, 121) 100%
  );
  text-align: center;
  border-radius: 0px 0px 35px 35px;

  .imageLogo {
    display: block;
    margin-top: 86px;
    margin-left: auto;
    margin-right: auto;
    img {
      max-width: 300px;
    }
    height: 62px;
  }
}

@media only screen and (min-height: 800px) {
  .btnForm {
    padding: 0px 41px 241px 41px;
  }
}
