.header {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(47, 48, 126, 1) 0%,
    rgba(88, 108, 157, 1) 100%
  );
  box-sizing: border-box;
  padding: calc(10px + env(safe-area-inset-top)) 20px 0px 20px;

  .goBackContainer {
    width: 100%;
    height: 13px;

    .buttonGoBack {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
    }

    svg {
      transform: rotate(180deg);
    }

    path {
      fill: white;
    }
  }

  .areaNameContainer {
    width: 100%;
    box-sizing: border-box;
    transition: 0.4s;
    padding-top: 71px;
    height: calc(100% - 13px);

    .areaName {
      width: 100%;
      height: 21px;
      font-size: 22px;
      font-weight: bold;
      text-align: left;
      color: #ffffff;
    }
  }
}

.fieldDetails {
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  background-color: white;
  padding: 25px 20px 0px 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 66px;
}

.sensorsList {
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  background-color: #f1f1f1;
  padding-top: 21px;
  width: 100%;
  height: 100%;
}

.memberDetailPage {
  box-sizing: border-box;
  background-color: #f8f8f8;
  padding: 25px 20px 0px 20px;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 100%;
}

.fieldAlertsPage {
  box-sizing: border-box;
  background-color: #f8f8f8;
  padding: 21px 0px 0px 0px;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 100%;
}

.memberInteractionsPage {
  box-sizing: border-box;
  background-color: white;
  padding-top: 20px;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 100%;
}

.fieldAlertDetailPage {
  box-sizing: border-box;
  background-color: #f8f8f8;
  padding: 35px 15px 0px 15px;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 100%;
}

.bodyWithHeaderRetracted {
  height: calc(100% - 85px);
  bottom: calc(0px - 100% + 84px + 75px);
}

.bodyExpanded {
  transition: 0.4s;
  height: calc(100% - 115px - env(safe-area-inset-top));
}
